@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;

#h,
#h2 {
  display: block;
  position: sticky;

  z-index: 1002;

  top: 0;
  left: 0;
  right: 0;

  margin: 0;
  font-family: 'Amazon Ember', 'Helvetica Neue', Roboto, Arial, sans-serif;

  [class^='awsui_logo'] {
    width: 32px !important;
    height: 32px !important;
  }

  [class^='awsui_icon'] {
    color: currentColor !important;
  }

  [class^='awsui_search'] {
    max-width: 360px !important;
    margin-left: 200px !important;
  }
}

#h {
  height: 95px;
}

.app-layout-body {
  margin-bottom: 80px;
}

.app-primary-top-navigation {
  [class^='awsui_padding-box'] {
    padding-left: 20px !important;
  }

  [class^='awsui_item-element_'][class*='awsui_variant-navigation_'][class*='awsui_highlighted_']:not(#\9) {
    color: inherit; // or any other color value

    &:hover {
      color: var(--color-text-accent-exu66k, #0073bb); // or any other color value you want on hover
    }
  }
}

.app-secondary-top-navigation {
  padding-left: 20px !important;
  height: 45px !important;
  min-height: 45px !important;
  background-color: #161d26 !important; // TODO: Replace with appropriate awsui variable when available  
  border-radius: 0 !important;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: center;

  .nav-items-container {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .global-secondary-nav-item {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 5px 10px;
    cursor: pointer;

    .display-name {
      color: awsui.$color-text-home-header-default !important;
      line-height: 1;
      white-space: nowrap;
    }

    &.highlighted-nav-item .display-name {
      color: awsui.$color-background-button-primary-default !important;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

.footer {
  display: block;
  position: fixed;
  height: 30px;

  z-index: 1002;

  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0px;
  padding: 0px;

  color: #ffffff !important;
  background: awsui.$color-background-home-header !important;

  padding-top: 0.5rem;
  padding-bottom: 0.1rem;
  padding-left: 2rem;
}

.xpt-no-access-layout-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.access-restricted-container {
  text-align: center;
}

.access-restricted-image {
  max-width: 50%;
  height: auto;
  margin-top: 20px;
}

.modify-access-modal-content {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  padding-right: 20px; /* To prevent content from touching the scrollbar */
}

.modify-access-modal-content::-webkit-scrollbar {
  width: 8px;
}

.modify-access-modal-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.modify-access-modal-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.modify-access-modal-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}